// SingleProduct.js

import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import RightIcon from "./../../assets/right.svg";
import RightIconAr from "./../../assets/chevron-right-md_ar.svg";

import HeartIcon from "components/HeartIcon";
import CounterBox from "components/CounterBox";
import SimpleCounterBox from "components/SimpleCounterBox";

import ImageSwiper from "components/imageSwiper";
import MyAccordion from "components/accordion";
import WriteReview from "components/WriteReview";
import MobileImageSwiper from "components/MobileImageSwiper";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import EmptyHeart from "./../../assets/empty_heart.svg";
import { useNavigate } from "react-router-dom";

// FCBAB6 icons  pink color
import ProductFCBAB6_1 from "./../../assets/FCBAB6_1.svg";
import ProductFCBAB6_2 from "./../../assets/FCBAB6_2.svg";
import ProductFCBAB6_3 from "./../../assets/FCBAB6_3.svg";
import ProductFCBAB6_4 from "./../../assets/FCBAB6_4.svg";
import ProductFCBAB6_5 from "./../../assets/FCBAB6_5.svg";

// 9ECBCE icons  labny color
import Product9ECBCE_1 from "./../../assets/9ECBCE_1.svg";
import Product9ECBCE_2 from "./../../assets/9ECBCE_2.svg";
import Product9ECBCE_3 from "./../../assets/9ECBCE_3.svg";
import Product9ECBCE_4 from "./../../assets/9ECBCE_4.svg";
import Product9ECBCE_5 from "./../../assets/9ECBCE_5.svg";

// ECE095 icons  yellow color
import ProductECE095_1 from "./../../assets/ECE095_1.svg";
import ProductECE095_2 from "./../../assets/ECE095_2.svg";
import ProductECE095_3 from "./../../assets/ECE095_3.svg";
import ProductECE095_4 from "./../../assets/ECE095_4.svg";
import ProductECE095_5 from "./../../assets/ECE095_5.svg";

// #EEDDB9 icons  gold color
import ProductEEDDB9_1 from "./../../assets/EEDDB9_1.svg";
import ProductEEDDB9_2 from "./../../assets/EEDDB9_2.svg";
import ProductEEDDB9_3 from "./../../assets/EEDDB9_3.svg";
import ProductEEDDB9_4 from "./../../assets/EEDDB9_4.svg";

// Pop Up Images
import PortablePopUp from "./../../assets/portable_pop_up.jpg";
import CleansePopUp from "./../../assets/cleanse_pop_up.jpg";
import CloseBtnSvg from "./../../assets/close-btn.svg";
import CloseWhiteBtnSvg from "./../../assets/CloseWhiteBtnSvg.svg";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Error from "./../../assets/error.png";
import { SyncLoader } from "react-spinners";

import User from "User";
import "./product.css";

import "./../../fonts/fonts.css";
import CartContext from "CartContext";

import { useTranslation } from "react-i18next";

const SingleProduct = () => {
  const [product, setProduct] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [productTheme, setProductTheme] = useState("#C6BC7E");
  const [isLiked, setisLiked] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [popup, setPopup] = useState(false);
  const popupRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const userData = User.fromLocalStorage();

    if (userData) {
      setUser(userData);

      if (userData.wishlist) {
        const isLiked = userData.wishlist.some(
          (item) => item.product_id === product.id
        ); // Update property name
        setisLiked(isLiked);
      }
    } else {
      setisLiked(false);
    }

    fetch(`https://dashboard.natindamiddleeast.com/api/getproduct/${id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setProduct(data.product);
        setProductTheme(data.product.theme);

        if ([7, 8, 9, 10].includes(data.product.id)) {
          // setPopup(true);
        } else {
          setPopup(false);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id, productTheme]);

  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, selectedQuantity);
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };
  const handlePairLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLike = async () => {
    try {
      setLoading(true);

      if (!user) {
        navigate("/login");
        return;
      }

      const productId = product.id;
      if (isLiked) {
        // Remove product from wishlist
        const response = await fetch(
          `https://dashboard.natindamiddleeast.com/api/removeFromWishlist`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.api_token}`,
            },
            body: JSON.stringify({ product_id: productId }),
          }
        );

        if (response.ok) {
          // Product removed successfully from wishlist
          user.wishlist = user.wishlist.filter(
            (item) => item.product_id !== productId
          );
          localStorage.setItem("user", JSON.stringify(user));
          setisLiked(false);
        } else {
          console.error("Failed to remove product from wishlist.");
        }
      } else {
        // Add product to wishlist
        const response = await fetch(
          `https://dashboard.natindamiddleeast.com/api/addToWishlist`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.api_token}`,
            },
            body: JSON.stringify({ product_id: productId }),
          }
        );

        if (response.ok) {
          // Product added successfully to wishlist
          user.wishlist.push({ product_id: productId });
          localStorage.setItem("user", JSON.stringify(user));
          setisLiked(true);
        } else {
          console.error("Failed to add product to wishlist.");
        }
      }
    } catch (error) {
      console.error("Error while handling like:", error);
    } finally {
      setLoading(false); // Clear loading state after processing the API call
    }
  };

  const handlePriceSelect = (price) => {
    setSelectedPrice(price);
  };
  const handleAddToCartGiftCard = (event) => {
    event.preventDefault();
    if (!selectedPrice) {
      alert("Please select a price option.");
      return;
    }
  };

  if (product.type === "gift_card")
    return (
      <main>
        <div className="single-product-web">
          <div className="single-product-container">
            <div className="gift-card-container">
              <div className="gift-card-image">
                <img src={product.medias[0].image_url} alt="gift card" />
              </div>
              <div className="gift-card-column">
                <div className="path">
                  <span>{t("Home")} </span>
                  <img
                    src={RightIcon}
                    style={{ marginRight: "5px", width: "16", height: "18" }}
                    alt="right-icon"
                  />
                  <span style={{ fontWeight: 500 }}>
                    {t(product.product_type)}
                  </span>
                  <img
                    src={RightIcon}
                    style={{ marginRight: "5px", width: "16", height: "18" }}
                    alt="right-icon"
                  />
                  <span style={{ fontWeight: 500 }}>
                    {" "}
                    {product[`name_${i18n.language.slice(-2)}`]}{" "}
                  </span>
                </div>

                <div className="product-like" onClick={handleLike}>
                  {isLiked ? (
                    <HeartIcon
                      color={productTheme}
                      width={30}
                      height={25}
                      alt="Heart"
                    />
                  ) : (
                    <img src={EmptyHeart} alt="empty heart" />
                  )}
                </div>

                <div className="single-product-name">
                  {" "}
                  {product[`name_${i18n.language.slice(-2)}`]}{" "}
                </div>

                <div className="price-selection-container">
                  {[
                    100, 150, 200, 250, 350, 400, 500, 550, 600, 650, 700, 750,
                    800, 850, 900, 950, 1000,
                  ].map((price, index) => (
                    <div
                      key={index}
                      className={`price-option ${
                        selectedPrice === price ? "selected" : ""
                      }`}
                      onClick={() => handlePriceSelect(price)}
                    >
                      {price} SAR
                    </div>
                  ))}
                </div>
                <div className="quantity-row">
                  <CounterBox ProductColor={productTheme} />
                  <button
                    className="single-product-add-to-cart"
                    style={{ background: product.theme }}
                    onClick={(event) =>
                      handleAddToCartGiftCard(product.id, event)
                    }
                    disabled={!selectedPrice}
                  >
                    {t("add_to_cart")} - {selectedPrice} SAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-product-mobile">
          <div className="path">
            <span>{t("Home")} </span>
            <img
              src={RightIcon}
              style={{ marginRight: "2px", width: "12", height: "12" }}
              alt="right-icon"
            />
            <span style={{ fontWeight: 500 }}>
              {" "}
              {product[`name_${i18n.language.slice(-2)}`]}{" "}
            </span>
          </div>
          <div className="gift-card-image-mobile">
            <img src={product.medias[0].image_url} alt="gift card" />
          </div>
          <div className="single-product-name">
            {" "}
            {product[`name_${i18n.language.slice(-2)}`]}{" "}
          </div>
          <div className="price-selection-container">
            {[
              100, 150, 200, 250, 350, 400, 500, 550, 600, 650, 700, 750, 800,
              850, 900, 950, 1000,
            ].map((price, index) => (
              <div
                key={index}
                className={`price-option ${
                  selectedPrice === price ? "selected" : ""
                }`}
                onClick={() => handlePriceSelect(price)}
              >
                {price} SAR
              </div>
            ))}
          </div>
          <div
            className="mobile-counter-button-container"
            style={{ backgroundColor: "#F8ECE6" }}
          >
            <div className="quantity-row">
              <CounterBox ProductColor={productTheme} />
              <button
                className="single-product-add-to-cart"
                style={{ background: product.theme }}
                onClick={(event) => handleAddToCartGiftCard(product.id, event)}
                disabled={!selectedPrice}
              >
                {t("add_to_cart")}- {selectedPrice} SAR
              </button>
            </div>
          </div>
        </div>
        <Footer productTheme={productTheme} />
      </main>
    );
  const navbarThemeMap = {
    "#9ECBCE": "#EDF6F6",
    "#FCBAB6": "#FBE9E8",
    "#ECE095": "#FBF9EA",
    "#EEDDB9": "#FAF3E5",
  };

  const HandleShopAll = () => {
    console.log(product.id);
    if (product.id === 8 || product.id === 10) {
      navigate("/product/cleanse-it-all-away-bundle/12");
    } else if (product.id === 7 || product.id === 9) {
      navigate("/product/your-portable-friends-bundle/13");
    }
  };

  const handleClosePopup = () => {
    setPopup(false);
  };

  const navbarTheme =
    navbarThemeMap[productTheme.toUpperCase()] || productTheme;

  const saleFlag =
    product.status === "Sale" && product.discounted_price ? true : false;

  const soldOutFlag = product.status === "Sold out" ? true : false;

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            height: "0vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : error ? (
        <div className="error-container">
          <img src={Error} alt="error" />
          <div className="error-text">{t("OOPS! No Product Found")}</div>
        </div>
      ) : (
        <main>
          <Navbar popUpFlag={popup} productTheme={navbarTheme} />
          <div className="single-product-web">
            <div className="single-product-container">
              <div className="product-upper-page">
                {/* image swiper hena */}
                <ImageSwiper
                  productTheme={productTheme}
                  images={product.medias}
                  saleFlag={saleFlag}
                  soldOutFlag={soldOutFlag}
                />
                <div className="product-upper-page-column">
                  <div className="path">
                    <span>{t("Home")}</span>
                    <img
                      src={i18n.language === "ar" ? RightIconAr : RightIcon}
                      style={{
                        marginRight: "5px",
                        width: "16px",
                        height: "18px",
                      }}
                      alt="right-icon"
                    />
                    <span>
                      <span>{t(product.product_type)}</span>
                    </span>
                    <img
                      src={i18n.language === "ar" ? RightIconAr : RightIcon}
                      style={{
                        marginRight: "5px",
                        width: "16px",
                        height: "18px",
                      }}
                      alt="right-icon"
                    />
                    <span style={{ fontWeight: 500 }}>
                      {product[`name_${i18n.language.slice(-2)}`]}
                    </span>
                  </div>
                  <div className="product-like" onClick={handleLike}>
                    {isLiked ? (
                      <HeartIcon
                        color={productTheme}
                        width={30}
                        height={25}
                        alt="Heart`"
                      />
                    ) : (
                      <img src={EmptyHeart} alt="empty heart" />
                    )}
                  </div>
                  {product.type === "bundle" && (
                    <>
                      <div
                        className="product-bundle-save"
                        style={{ color: product.theme }}
                      >
                        <span style={{ fontWeight: 400 }}>{t("Save")} </span>
                        {product.id === 12
                          ? ` 210 ${t("SAR")}`
                          : product.id === 13
                          ? `210 ${t("SAR")}`
                          : ""}
                      </div>
                      <div style={{ height: "10px" }}></div>
                    </>
                  )}

                  <div className="single-product-name">
                    {product[`name_${i18n.language.slice(-2)}`]}{" "}
                  </div>
                  <div className="single-product-description">
                    {product.type === "bundle"
                      ? i18n.language === "ar"
                        ? product.short_description_ar
                            .split("\n")
                            .map((item, index) => <div key={index}>{item}</div>)
                        : product.short_description
                            .split("\n")
                            .map((item, index) => <div key={index}>{item}</div>)
                      : i18n.language === "ar"
                      ? product.short_description_ar
                      : product.short_description}
                  </div>

                  {product.type === "standard" && (
                    <div className="single-product-hearts-container">
                      {Array.from(
                        { length: Math.round(product.avg_rating) },
                        (_, index) => (
                          <HeartIcon
                            key={index}
                            color={productTheme}
                            width={30}
                            height={25}
                            alt={`Heart ${index + 1}`}
                          />
                        )
                      )}
                      <div className="single-product-num_reviews">
                        ({product.num_reviews})
                      </div>
                    </div>
                  )}

                  <hr
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                      height: "1px",
                      marginBottom: "40px",
                    }}
                  />
                  <div className="product-description-rest">
                    {product[`description_${i18n.language.slice(-2)}`]}{" "}
                  </div>
                  <hr
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                      height: "1px",
                      marginTop: "40px",
                    }}
                  />
                  <div className="quantity-row" style={{ direction: "ltr" }}>
                    <SimpleCounterBox
                      ProductColor={productTheme}
                      initialQuantity={1}
                      maxQuantity={10}
                      onQuantityChange={(quantity) =>
                        setSelectedQuantity(quantity)
                      }
                    />
                    <button
                      className="single-product-add-to-cart"
                      style={{
                        background:
                          product.status === "Sold out"
                            ? "#D3D3D3"
                            : productTheme,
                        cursor:
                          product.status === "Sold out"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={
                        product.status === "Sold out"
                          ? null
                          : (event) => handleAddToCart(product.id, event)
                      }
                      disabled={product.status === "Sold out"}
                    >
                      {product.status === "Sold out" ? (
                        <>{t("out_of_stock")}</>
                      ) : product.type === "bundle" ? (
                        <>
                          {t("add_to_cart")} -{" "}
                          <span
                            style={{
                              marginRight: "10px",
                            }}
                            className="strikethrough"
                          >
                            304 {t("SAR")}
                          </span>
                          {product.price} {t("SAR")}
                        </>
                      ) : product.status === "Sale" &&
                        product.discounted_price ? (
                        <>
                          {t("add_to_cart")} -{" "}
                          <span
                            style={{
                              marginRight: "7px",
                            }}
                            className="strikethrough"
                          >
                            {" "}
                            {product.price} {t("SAR")}
                          </span>{" "}
                          {product.discounted_price} {t("SAR")}
                        </>
                      ) : (
                        <>
                          {t("add_to_cart")} - {product.price} {t("SAR")}
                        </>
                      )}
                    </button>
                  </div>

                  {/* FCBAB6 pink */}
                  {product.theme === "#FCBAB6" && (
                    <div className="product-icons-container">
                      <div className="single-product-icons-container">
                        <img src={ProductFCBAB6_4} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Allergy Tested")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductFCBAB6_1} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("No Paws Harmed")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductFCBAB6_2} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Dermatology tested")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductFCBAB6_5} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Green")}{" "}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductFCBAB6_3} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("For all skin types")}
                        </span>
                      </div>
                    </div>
                  )}
                  {/* 9ECBCE */}
                  {product.theme === "#9ECBCE" && (
                    <div className="product-icons-container">
                      <div className="single-product-icons-container">
                        <img src={Product9ECBCE_1} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Allergy Tested")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={Product9ECBCE_2} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("No Paws Harmed")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={Product9ECBCE_3} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Dermatology tested")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={Product9ECBCE_4} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Non-Comedogenic")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={Product9ECBCE_5} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("For all skin types")}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* ECE095 */}
                  {product.theme === "#ECE095" && (
                    <div className="product-icons-container">
                      <div className="single-product-icons-container">
                        <img src={ProductECE095_1} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Allergy Tested")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductECE095_2} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("No Paws Harmed")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductECE095_3} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Dermatology tested")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductECE095_4} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Non-Comedogenic")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductECE095_5} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("For all skin types")}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* EEDDB9 */}
                  {product.theme === "#EEDDB9" && (
                    <div className="product-icons-container">
                      <div className="single-product-icons-container">
                        <img src={ProductEEDDB9_1} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Vegan")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductEEDDB9_2} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("No Paws Harmed")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductEEDDB9_3} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("Dermatology tested")}
                        </span>
                      </div>

                      <div className="single-product-icons-container">
                        <img src={ProductEEDDB9_4} alt="FCBAB6" />
                        <span style={{ color: product.theme }}>
                          {t("For all skin types")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* standred product */}
            {product.type === "standard" && (
              <>
                <MyAccordion product={product} ProductColor={productTheme} />
                <div className="pair-it-with">{t("Pair it with")}</div>
                <Link
                  className="pair-product"
                  to={`/product/${encodeURIComponent(
                    product.pair_it_with.name_en
                      .replace(/\s+/g, "-")
                      .toLowerCase()
                  )}/${product.pair_it_with.id}`}
                  onClick={handlePairLinkClick}
                >
                  {product.pair_it_with.status === "Sale" &&
                    product.pair_it_with.discounted_price && (
                      <div
                        className="sale-badge"
                        style={{
                          backgroundColor: product.pair_it_with.theme,
                          right: "25%",
                        }}
                      >
                        {t("Sale")}
                      </div>
                    )}

                  {product.pair_it_with.status === "Sold out" && (
                    <div
                      className="sale-badge"
                      style={{
                        backgroundColor: product.pair_it_with.theme,
                        right: "25%",
                      }}
                    >
                      {t("out of stock")}
                    </div>
                  )}
                  <img
                    src={product.pair_it_with.medias[0].image_url}
                    alt={product.tag}
                  />
                  <div className="pair-name">
                    {product.pair_it_with[`name_${i18n.language.slice(-2)}`]}{" "}
                  </div>
                  <div className="hearts-container">
                    {Array.from(
                      { length: Math.round(product.avg_rating) },
                      (_, index) => (
                        <HeartIcon
                          key={index}
                          color={product.pair_it_with.theme}
                          width={30}
                          height={25}
                          alt={`Heart ${index + 1}`}
                        />
                      )
                    )}
                  </div>
                  <div className="pair-button">
                    <button
                      className="single-product-add-to-cart"
                      style={{
                        background:
                          product.pair_it_with.status === "Sold out"
                            ? "#D3D3D3"
                            : product.pair_it_with.theme,
                        cursor:
                          product.pair_it_with.status === "Sold out"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={
                        product.pair_it_with.status === "Sold out"
                          ? null
                          : (event) =>
                              handleAddToCart(product.pair_it_with.id, event)
                      }
                      disabled={product.pair_it_with.status === "Sold out"}
                    >
                      {product.pair_it_with.status === "Sold out" ? (
                        <>{t("OUT OF STOCK")}</>
                      ) : product.pair_it_with.type === "bundle" ? (
                        <>
                          {t("add_to_cart")} -{" "}
                          <span
                            style={{
                              marginRight: "7px",
                            }}
                            className="strikethrough"
                          >
                            304 {t("SAR")}
                          </span>
                          {product.pair_it_with.price} {t("SAR")}
                        </>
                      ) : product.pair_it_with.status === "Sale" &&
                        product.pair_it_with.discounted_price ? (
                        <>
                          {t("add_to_cart")} -{" "}
                          <span
                            style={{
                              marginRight: "7px",
                            }}
                            className="strikethrough"
                          >
                            {product.pair_it_with.price} {t("SAR")}
                          </span>
                          {product.pair_it_with.discounted_price} {t("SAR")}
                        </>
                      ) : (
                        <>
                          {t("add_to_cart")} - {product.pair_it_with.price}{" "}
                          {t("SAR")}
                        </>
                      )}
                    </button>
                  </div>
                </Link>
                <div className="reviews-center">{t("REVIEWS")}</div>
                <div className="reviews-hearts">
                  {Array.from(
                    { length: Math.round(product.avg_rating) },
                    (_, index) => (
                      <HeartIcon
                        key={index}
                        color={productTheme}
                        width={25}
                        height={21}
                        alt={`Heart ${index + 1}`}
                      />
                    )
                  )}
                </div>

                <button
                  className="comment-box"
                  style={{ border: `1px solid ${productTheme}` }}
                >
                  <a href="#write-review">
                    {t("Leave a review & earn points!")}
                  </a>
                </button>
                <WriteReview
                  ProductColor={productTheme}
                  ProductId={product.id}
                />
                <div className="single-prouduct-reviews">
                  {product.reviews.map((review) => (
                    <div key={review.id} className="review-item">
                      <div className="review-title">{review.title}</div>
                      <div className="reviews-hearts-container">
                        {Array.from(
                          { length: Math.round(product.avg_rating) },
                          (_, index) => (
                            <HeartIcon
                              key={index}
                              color={productTheme}
                              width={20}
                              height={18}
                              alt={`Heart ${index + 1}`}
                            />
                          )
                        )}
                        {/* <div className="num_reviews">({product.num_reviews})</div> */}
                      </div>

                      <div className="review-comment">
                        {review.review_comment}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {/* gift or bundle product */}
          </div>
          <div className="single-product-mobile">
            <div className="path">
              <span>{t("Home")} </span>
              <img
                src={i18n.language === "ar" ? RightIconAr : RightIcon}
                style={
                  i18n.language === "ar"
                    ? { marginLeft: "2px", width: "12px", height: "12px" }
                    : { marginRight: "2px", width: "12px", height: "12px" }
                }
                alt="right-icon"
              />
              <span>{t(product.product_type)}</span>
              <img
                src={i18n.language === "ar" ? RightIconAr : RightIcon}
                style={
                  i18n.language === "ar"
                    ? { marginLeft: "2px", width: "12px", height: "12px" }
                    : { marginRight: "2px", width: "12px", height: "12px" }
                }
                alt="right-icon"
              />
              <span style={{ fontWeight: 500 }}>
                {product[`name_${i18n.language.slice(-2)}`]}{" "}
              </span>
            </div>

            <div className="MobileImageSwiperpadding">
              {product.type === "bundle" && (
                <MobileImageSwiper
                  images={product.medias.filter(
                    (image) => image.tag === "main" || image.tag === "square"
                  )}
                />
              )}
              {product.type === "standard" && (
                <MobileImageSwiper
                  images={product.medias.filter(
                    (image) => image.tag === "square"
                  )}
                  saleFlag={saleFlag}
                  productTheme={productTheme}
                />
              )}
            </div>
            {product.type === "bundle" && (
              <>
                <div
                  className="product-bundle-save"
                  style={{ color: product.theme }}
                >
                  <span style={{ fontWeight: 400 }}>{t("Save")} </span>
                  {product.id === 12
                    ? ` 210 ${t("SAR")}`
                    : product.id === 13
                    ? `210 ${t("SAR")}`
                    : ""}
                </div>
                <div style={{ height: "10px" }}></div>
              </>
            )}
            <div className="single-product-name">
              {" "}
              {product[`name_${i18n.language.slice(-2)}`]}{" "}
            </div>
            <div className="single-product-description">
              {i18n.language === "ar"
                ? product.short_description_ar
                : product.short_description}
            </div>
            {product.type === "standard" && (
              <div className="single-product-hearts-container">
                {Array.from(
                  { length: Math.round(product.avg_rating) },
                  (_, index) => (
                    <HeartIcon
                      key={index}
                      color={productTheme}
                      width={25}
                      height={20}
                      alt={`Heart ${index + 1}`}
                    />
                  )
                )}
                <div className="single-product-num_reviews">
                  ({product.num_reviews})
                </div>
              </div>
            )}
            {product.type === "bundle" && (
              <div
                style={{ marginTop: "20px" }}
                className="product-description-rest"
              >
                {product[`description_${i18n.language.slice(-2)}`]}{" "}
              </div>
            )}
            {product.type === "standard" && (
              <div className="product-description-rest">
                {product[`description_${i18n.language.slice(-2)}`]}{" "}
              </div>
            )}

            <hr
              style={{
                backgroundColor: "#818181",
                height: "0.5px",
                margin: "40px 20px",
              }}
            />

            {product.type === "standard" && (
              <>
                <div
                  className="single-product-title"
                  style={{ color: productTheme }}
                >
                  {t("DETAILS")}
                </div>
                <div
                  className="single-product-details"
                  style={{ lineHeight: "22px" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "ar"
                        ? product.details_ar
                        : product.details_en,
                  }}
                ></div>
                <hr
                  style={{
                    backgroundColor: "#818181",
                    height: "0.5px",
                    margin: "0px 20px",
                    marginTop: "20px",
                  }}
                />

                <Accordion
                  className="terms-accordion"
                  sx={{
                    boxShadow: "none",
                    padding: "20px",
                    border: "none",
                    "&:before": { display: "none" },
                    "&:not(:last-child)": { marginBottom: "0px" },
                    "&.Mui-expanded": { marginBottom: "10px" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panela-content`}
                    id={`panela-header`}
                    sx={{
                      borderBottom: "1px solid #e0e0e0",
                      color: productTheme,
                      fontWeight: 500,
                    }}
                  >
                    {t("INGREDIENTS")}
                  </AccordionSummary>
                  <AccordionDetails>
                    {product[`ingredients_${i18n.language.slice(-2)}`]}{" "}
                  </AccordionDetails>
                </Accordion>
                <hr
                  style={{
                    backgroundColor: "#818181",
                    height: "0.5px",
                    margin: "0px 20px",
                  }}
                />
              </>
            )}
            {/* FCBAB6 pink */}
            {product.theme === "#FCBAB6" && (
              <div
                className="product-icons-container"
                style={{ marginTop: "20px" }}
              >
                <div className="single-product-icons-container">
                  <img src={ProductFCBAB6_4} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Allergy Tested")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductFCBAB6_1} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("No Paws Harmed")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductFCBAB6_2} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Dermatology tested")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductFCBAB6_5} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>{t("Green")} </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductFCBAB6_3} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("For all skin types")}
                  </span>
                </div>
              </div>
            )}
            {/* 9ECBCE */}
            {product.theme === "#9ECBCE" && (
              <div
                className="product-icons-container"
                style={{ marginTop: "20px" }}
              >
                <div className="single-product-icons-container">
                  <img src={Product9ECBCE_1} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Allergy Tested")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={Product9ECBCE_2} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("No Paws Harmed")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={Product9ECBCE_3} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Dermatology tested")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={Product9ECBCE_4} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Non-Comedogenic")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={Product9ECBCE_5} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("For all skin types")}
                  </span>
                </div>
              </div>
            )}

            {/* ECE095 */}
            {product.theme === "#ECE095" && (
              <div
                className="product-icons-container"
                style={{ marginTop: "20px" }}
              >
                <div className="single-product-icons-container">
                  <img src={ProductECE095_1} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Allergy Tested")}d
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductECE095_2} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("No Paws Harmed")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductECE095_3} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Dermatology tested")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductECE095_4} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Non-Comedogenic")}{" "}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductECE095_5} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("For all skin types")}
                  </span>
                </div>
              </div>
            )}

            {/* EEDDB9 */}
            {product.theme === "#EEDDB9" && (
              <div
                className="product-icons-container"
                style={{ marginTop: "20px" }}
              >
                <div className="single-product-icons-container">
                  <img src={ProductEEDDB9_1} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>{t("Vegan")}</span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductEEDDB9_2} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("No Paws Harmed")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductEEDDB9_3} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("Dermatology tested")}
                  </span>
                </div>

                <div className="single-product-icons-container">
                  <img src={ProductEEDDB9_4} alt="FCBAB6" />
                  <span style={{ color: product.theme }}>
                    {t("For all skin types")}
                  </span>
                </div>
              </div>
            )}
            {product.type === "standard" && (
              <>
                <hr
                  style={{
                    backgroundColor: "#818181",
                    height: "0.5px",
                    margin: "20px 20px",
                  }}
                />

                <div
                  className="single-product-title"
                  style={{ color: productTheme }}
                >
                  {t("HOW TO USE")}
                </div>
                <div
                  className="single-product-details"
                  style={{ marginBottom: "20px", lineHeight: "22px" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "ar"
                        ? product.how_to_use_ar
                        : product.how_to_use_en,
                  }}
                ></div>
                <div
                  className="single-product-title"
                  style={{ color: productTheme }}
                >
                  {t("WHY WE LOVE IT")}
                </div>
                <div
                  className="single-product-details"
                  style={{ marginBottom: "40px" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "ar"
                        ? product.why_we_love_it_ar
                        : product.why_we_love_it_en,
                  }}
                ></div>
                <div
                  className="hero-ingredients"
                  style={{ color: productTheme }}
                >
                  {t("HERO NATURAL INGREDIENTS")}
                </div>

                <MobileImageSwiper
                  images={product.medias.filter((image) => {
                    if (i18n.language === "ar") {
                      return image.tag === "hero_natural_ingredients_ar";
                    } else {
                      return image.tag === "hero_natural_ingredients";
                    }
                  })}
                  isHeroNaturalIngredients={true}
                />
                <div
                  className="single-product-title"
                  style={{ color: productTheme, marginTop: "20px" }}
                >
                  {t("RECOMMENDED FOR")}
                </div>
                <div
                  className="single-product-details"
                  style={{ lineHeight: "22px" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "ar"
                        ? product.recommended_for_ar
                        : product.recommended_for_en,
                  }}
                ></div>
                <hr
                  style={{
                    backgroundColor: "#818181",
                    height: "0.5px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                  }}
                />
                {/* FAQ Accordion Section */}
                <div
                  className="single-product-title"
                  style={{ color: productTheme }}
                >
                  {t("FAQs")}
                </div>
                {product.faqs.map((faq, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      boxShadow: "none",
                      m: "10px 0",
                      border: "none", // Remove borders
                      "&.MuiPaper-elevation1": { boxShadow: "none" },
                      "&:before": {
                        // Remove the default MUI border line
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon sx={{ color: productTheme }} />
                      }
                      aria-controls={`faq-content-${index}`}
                      id={`faq-header-${index}`}
                      sx={{
                        typography: "body1",
                        color: "#000",
                        background: "transparent", // Ensure background is transparent
                        borderBottom: "none", // Remove bottom border specifically
                        "& .MuiAccordionSummary-content": {
                          justifyContent: "flex-start",
                          fontFamily: '"Work Sans", sans-serif',
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "24px",
                          margin: "5px",
                        },
                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                          {
                            transform: "rotate(180deg)", // Rotate icon upward when expanded
                          },
                        "&.Mui-expanded": {
                          minHeight: "48px", // Adjust minimum height if needed
                          margin: "0px", // Remove default MUI margin
                        },
                      }}
                    >
                      <div>{faq[`question_${i18n.language.slice(-2)}`]} </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        typography: "body2",
                        fontFamily: '"Work Sans", sans-serif',
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        margin: "5px",
                        padding: "0 16px 16px", // Adjust padding if needed
                        borderTop: "none", // Ensure no top border
                      }}
                    >
                      {faq.answer_en.includes(
                        "https://drive.google.com/file/d/1jPlfSpxgXK0-8KbVYSxVVTjfWubfAuIh/view?usp=drive_link"
                      ) ? (
                        <a
                          href="https://drive.google.com/file/d/1jPlfSpxgXK0-8KbVYSxVVTjfWubfAuIh/view?usp=drive_link"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "blue" }}
                        >
                          {t("Certificate of proof")}
                        </a>
                      ) : (
                        <div>{faq[`answer_${i18n.language.slice(-2)}`]} </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}

                <div className="reviews-center">{t("REVIEWS")}</div>
                <div className="reviews-hearts">
                  {Array.from(
                    { length: Math.round(product.avg_rating) },
                    (_, index) => (
                      <HeartIcon
                        key={index}
                        color={productTheme}
                        width={25}
                        height={21}
                        alt={`Heart ${index + 1}`}
                      />
                    )
                  )}
                  <div className="num_reviews">({product.num_reviews})</div>
                </div>
                <button
                  className="comment-box"
                  style={{ border: `1px solid ${productTheme}` }}
                >
                  <Link to={`/products/${product.id}/reviews`}>
                    {t("Leave a review & earn points!")}
                  </Link>
                </button>
              </>
            )}

            <div
              className="fixed-add-to-cart"
              style={{ backgroundColor: `${navbarTheme}` }}
            >
              <div className="quantity-row">
                <SimpleCounterBox
                  ProductColor={productTheme}
                  initialQuantity={1}
                  maxQuantity={10}
                  onQuantityChange={(quantity) => setSelectedQuantity(quantity)}
                />
                <button
                  className="single-product-add-to-cart"
                  style={{
                    background:
                      product.status === "Sold out" ? "#D3D3D3" : product.theme,
                    cursor:
                      product.status === "Sold out" ? "not-allowed" : "pointer",
                  }}
                  onClick={
                    product.status === "Sold out"
                      ? null
                      : (event) => handleAddToCart(product.id, event)
                  }
                  disabled={product.status === "Sold out"}
                >
                  {product.status === "Sold out" ? (
                    <>{t("OUT OF STOCK")}</>
                  ) : product.type === "bundle" ? (
                    <>
                      {t("add_to_cart")} -{" "}
                      <span
                        style={{
                          marginRight: "7px",
                        }}
                        className="strikethrough"
                      >
                        304 {t("SAR")}
                      </span>
                      {product.price} {t("SAR")}
                    </>
                  ) : product.status === "Sale" && product.discounted_price ? (
                    <>
                      {t("add_to_cart")} -{" "}
                      <span
                        style={{
                          marginRight: "7px",
                        }}
                        className="strikethrough"
                      >
                        {product.price} {t("SAR")}
                      </span>{" "}
                      {product.discounted_price} {t("SAR")}
                    </>
                  ) : (
                    <>
                      {t("add_to_cart")} - {product.price} {t("SAR")}
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>

          {popup && (
            <>
              <div className="blurry-background"></div>
              <div className="bundle-pop-up" ref={popupRef}>
                <div>
                  {product.id === 8 || product.id === 10 ? (
                    <img src={CleansePopUp} alt="CleansePopUp" />
                  ) : product.id === 7 || product.id === 9 ? (
                    <img src={PortablePopUp} alt="PortablePopUp" />
                  ) : null}
                </div>

                <div className="bundle-pop-up-column">
                  <div className="bundle-pop-up-x" onClick={handleClosePopup}>
                    <img src={CloseBtnSvg} alt="CloseBtnSvg" />
                  </div>
                  {product.id === 8 || product.id === 10 ? (
                    <>
                      <span>Get 50% OFF the Double Cleansing Bundle</span>
                    </>
                  ) : product.id === 7 || product.id === 9 ? (
                    <>
                      <span>Get 50% OFF the Portable Sticks Bundle!</span>
                    </>
                  ) : null}
                  <div className="bundle-pop-up-sub">
                    Add it to your cart now before it's gone!
                  </div>
                  <div className="bundle-pop-up-button-div">
                    <button
                      className="bundle-pop-up-button"
                      type="submit"
                      onClick={HandleShopAll}
                    >
                      SHOP NOW
                    </button>
                  </div>
                </div>
                <div
                  className="bundle-pop-up-x-white"
                  onClick={handleClosePopup}
                >
                  <img src={CloseWhiteBtnSvg} alt="CloseWhiteBtnSvg" />
                </div>
              </div>
            </>
          )}
          <Footer productTheme={productTheme} />
        </main>
      )}
    </div>
  );
};
export default SingleProduct;
